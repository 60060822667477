import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

export default (props) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return null
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return children
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return `
          <ol>
            ${children.map((child) => `<li>${child}</li>`)}
          </ol>
        `
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return `
          <ul>
            ${children.map((child) => `<li>${child}</li>`)}
          </ul>
        `
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return children.join(', ')
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return children
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return `
          <a href=${node.data.uri}>${children}</a>
        `
      },
    },
  }

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            ${props.data.map((q) => {
              return `
                {
                  "@type": "Question",
                  "name": "${q.question}",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "${renderRichText(q.answer, options)}"
                  }
                }
              `
            })}
          ]
        }`,
        }}
      />
    </>
  )
}
