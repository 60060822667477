// extracted by mini-css-extract-plugin
export var animateDown = "faq_content-module--animateDown--96e72";
export var animateUp = "faq_content-module--animateUp--fb222";
export var answerText = "faq_content-module--answerText--5d685";
export var arrowImg = "faq_content-module--arrowImg--04d2b";
export var arrowUp = "faq_content-module--arrowUp--d3260";
export var container = "faq_content-module--container--c831a";
export var content = "faq_content-module--content--5fb9a";
export var h2 = "faq_content-module--h2--4ea26";
export var image = "faq_content-module--image--287f2";
export var imageAnswer = "faq_content-module--imageAnswer--9e476";
export var leftContent = "faq_content-module--leftContent--d0fd9";
export var liAnswer = "faq_content-module--liAnswer--5b9d7";
export var liButton = "faq_content-module--liButton--cc686";
export var liContent = "faq_content-module--liContent--53b52";
export var liCounter = "faq_content-module--liCounter--1ff37";
export var liFaqItem = "faq_content-module--liFaqItem--b0e1c";
export var liImg = "faq_content-module--liImg--5c41c";
export var liLeftText = "faq_content-module--liLeftText--d4112";
export var liParagraph = "faq_content-module--liParagraph--b806c";
export var liQuestionItem = "faq_content-module--liQuestionItem--4521c";
export var liQuestionTitle = "faq_content-module--liQuestionTitle--7700b";
export var liQuestions = "faq_content-module--liQuestions--758fb";
export var liQuestionsParent = "faq_content-module--liQuestionsParent--86db5";
export var liSection = "faq_content-module--liSection--ae755";
export var liTitle = "faq_content-module--liTitle--390e4";
export var linkButton = "faq_content-module--linkButton--8c372";
export var linkButtonTitle = "faq_content-module--linkButtonTitle--4e6a6";
export var linkButtonTitleBlack = "faq_content-module--linkButtonTitleBlack--24e6d";
export var linkDiv = "faq_content-module--linkDiv--08953";
export var linkDiv2 = "faq_content-module--linkDiv2--6bee9";
export var linkSection = "faq_content-module--linkSection--88ed3";
export var section = "faq_content-module--section--934f4";
export var whiteBg = "faq_content-module--whiteBg--a5178";
export var wrapImg = "faq_content-module--wrapImg--eb2ac";