import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import OtherHero from '../components/other_hero/other_hero'
import FAQContent from '../components/faq_content/faq_content'
import { assetNames } from '../utilities.js/constants'

import './base.css'
import Layout from '../components/website_layout/layout'
// import IntercomLoader from '../components/ui/intercom'
// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const FAQ = (props) => {
  const assets = assetNames.reduce((res, assetName) => {
    res[assetName] = props.data[assetName]?.gatsbyImageData
    return res
  }, {})

  useEffect(() => {
    if (isBrowser) {
      // Track seeing this page
      window.analytics.track('website_page_viewed', {
        page: 'faq',
      })
    }
  }, [])

  const siteMetadata = props.data.contentfulSiteMetadata
  // Override for page meta and title
  siteMetadata.title = props.data.contentfulFaqPageContent.title
  siteMetadata.description = props.data.contentfulFaqPageContent.description

  return (
    <div>
      <Layout
        data={props.data.contentfulFaqPageContent}
        seo={siteMetadata}
        assets={assets}
        showScrollToTop={true}
      >
        <OtherHero
          assets={assets}
          data={props.data?.contentfulFaqPageContent.mainContent}
          mobileNoShowImage={true}
        />
        <FAQContent data={props.data?.contentfulFaqPageContent.faqContent} />
        {/* <IntercomLoader /> */}
      </Layout>
    </div>
  )
}

export default FAQ

export const pageQuery = graphql`
  query FaqQuery {
    contentfulFaqPageContent(contentful_id: { eq: "5GCDgkeewdzmJxGvqMuRGg" }) {
      title
      description
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      mainContent {
        rightImage {
          gatsbyImageData(width: 800)
          title
        }
        subtitle
        topBubbleText
        title
        fieldPlaceholder
        fieldButton {
          label
          url
        }
        formTitle
      }
      faqContent {
        section {
          subtitle
          title
          image {
            gatsbyImageData(width: 80)
            title
          }
          question {
            question
            answer {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  gatsbyImageData
                  title
                }
              }
            }
            questionID
          }
        }
      }
      ctaSectionContent {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section2Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }

        section3Paths {
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
