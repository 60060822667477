import React, { useEffect } from 'react'
import Section from '../ui/section'
import Container from '../ui/container'
import Typography from '../ui/typography'
import * as styles from './faq_content.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import Wrap from '../../../static/assets/img/wrap.svg'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import BlueArrow from '../../../static/assets/img/arrow-top-right-blue.svg'
import SchemaScript from '../schema_script/schema_script'

const isBrowser = typeof window !== 'undefined'

const { Paragraph } = Typography

export default ({ data }) => {
  const isPageFAQ = isBrowser && window.location.href.includes('faq')
  let newList = []
  for (let i = 0; i < data.section.length; i++) {
    let quest = data.section[i].question
    var anyBoxesChecked = new Array(quest.length).fill(false)
    newList.push(anyBoxesChecked)
  }

  const trackFaqClicked = (title, question, questionID) => {
    if (isBrowser) {
      const details = document.getElementById(questionID)
      if (details && details.open) {
        // only send event IFF faq item was opened
        window.analytics.track('website_faq_question', { title, question })
      }
    }
  }

  const openDetails = () => {
    if (isBrowser) {
      const hash = window.location.hash.substring(1)
      if (hash) {
        const details = document.getElementById(hash)
        if (details && details.tagName.toLowerCase() === 'details') {
          details.open = true
        }
      }
    }
  }

  useEffect(() => {
    if (isBrowser) {
      // manually open if webpage is first loaded
      const hash = window.location.hash.substring(1)
      if (hash) {
        openDetails()
      }
      // add listener for others
      const listener = window.addEventListener('hashchange', openDetails)
      return listener
    }
  }, [])

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          // asset is not an image
          return null
        }
        return (
          <div>
            <GatsbyImage
              alt={node.data.target.title}
              image={gatsbyImageData}
              className={styles.imageAnswer}
              imgStyle={{ objectFit: 'contain' }}
            />
            <div className="h-5" />
          </div>
        )
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol>
            <div className="h-5" />
            {children.map((child, idx) => (
              <li key={idx}>
                <span key={idx}>
                  {idx + 1}. {child}
                </span>
              </li>
            ))}
            <div className="h-5" />
          </ol>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => (
        <ol>
          <div className="h-5" />
          {children.map((child, idx) => (
            <li key={idx}>
              <span key={idx}>• {child}</span>
            </li>
          ))}
          <div className="h-5" />
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <span>
          {children.map((child, idx) => (
            <span key={idx}>{child}</span>
          ))}
        </span>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <span>
            {children}
            <div />
          </span>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <div>
            <br />
            {children}
            <br />
            <br />
          </div>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <span>
            <a className="text-blue" href={node.data.uri}>
              {children}
            </a>
          </span>
        )
      },
    },
  }

  return (
    <div className={styles.whiteBg}>
      <Section className={styles.section}>
        <Container className={styles.container}>
          {data.section.map((f, idx) => (
            <div key={idx} className={styles.liSection}>
              <div className={styles.leftContent}>
                <div className="flex items-center w-full h-max">
                  <GatsbyImage
                    alt={f.image.title}
                    className={styles.image}
                    image={f.image.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                  <div className="flex flex-col ml-4">
                    <Paragraph className={styles.liTitle}>{f.title}</Paragraph>
                    <Paragraph className={styles.liParagraph}>
                      {f.subtitle}
                    </Paragraph>
                  </div>
                </div>
              </div>
              {/* FAQ questions in here */}
              <div className={styles.liQuestionsParent}>
                {/* Each question is mapped here */}
                {f.question.map((g, idy) => (
                  <details
                    key={100 * idx + idy}
                    id={g.questionID}
                    className={styles.liQuestions}
                    onClick={() =>
                      trackFaqClicked(g.question, g.answer, g.questionID)
                    }
                  >
                    <summary className={styles.liQuestionItem}>
                      {g.question}
                      <img
                        src={Wrap}
                        className={styles.arrowUp}
                        alt="faq dropdown arrow"
                      />
                    </summary>
                    {/* Answer is here */}
                    <div id={g.id} className={styles.liAnswer}>
                      {g.answer && renderRichText(g.answer, options)}
                    </div>
                  </details>
                ))}
              </div>
            </div>
          ))}
          <SchemaScript
            data={data.section.reduce((acc, cur) => {
              acc = [...acc, ...cur.question]

              return acc
            }, [])}
          />
          {/* Should only be shown on other pages than FAQ */}
          {isBrowser && window && (
            <div className={styles.linkSection}>
              <div className={styles.linkDiv}></div>
              <div className={styles.linkDiv2}>
                <Paragraph className={styles.linkButtonTitleBlack}>
                  {isPageFAQ
                    ? 'Still need help? Contact'
                    : 'Have more questions?'}
                </Paragraph>
                <a
                  href={isPageFAQ ? 'mailto:support@wagetap.com' : '/faq/'}
                  className={styles.linkButton}
                >
                  <Paragraph className={styles.linkButtonTitle}>
                    {isPageFAQ ? 'support@wagetap.com' : 'Read more FAQ'}
                  </Paragraph>
                  {!isPageFAQ && (
                    <img
                      src={BlueArrow}
                      className={styles.arrowImg}
                      alt="faq linking arrow"
                    />
                  )}
                </a>
              </div>
            </div>
          )}
        </Container>
      </Section>
    </div>
  )
}
